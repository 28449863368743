import { Fragment } from "react";
// import Entity from "../../UI/box/entity/Entity";
import Title from "../../UI/box/title/Title";
import React from "react";
import classes from "./diav.module.css";

const Diav = () => {
  // const controller = new AbortController();
  // const { signal } = controller;

  // const [diavData, setDiavData] = useState([]);

  // useEffect(() => {
  //   const callTest = async () => {
  //     const req = await fetch(
  //       "https://zitsa.smartiscity.gr/api/diavouleuseis2.php",
  //       { signal }
  //     ).catch((error) => console.log(error));
  //
  //     if (req === undefined || !req.ok) return [];
  //     const response = await req.json();
  //
  //     setDiavData(response);
  //   };
  //
  //   callTest();
  //
  //   return () => controller.abort();
  // }, []);
  return (
    <Fragment>
      <Title
        icon={"im im-icon-Speach-BubbleDialog"}
        title={"ΔΗΜΟΣΙΕΣ ΔΙΑΒΟΥΛΕΥΣΕΙΣ"}
        externalLink={"https://zitsa.diavouleuseis.gr"}
        infoContext={"Παρακολουθήστε και συμμετέχετε ενεργά στη δημόσια διαβούλευση για τη διαδικασία λήψης αποφάσεων κατά τον σχεδιασμό και την εφαρμογή έργων και πολιτικών στο Δήμο μας"}
		className={classes.wtitle}
        element={
          <i
            id="draggable"
            className="im im-icon-Double-Tap"
            style={{ fontSize: "32px", minWidth: "32px", cursor: "pointer" }}
          />
        }
      />
      {/*{diavData.map((data, index) => (*/}
      {/*  <Entity key={index} content={data.name} value={data.value} />*/}
      {/*))}*/}
      <p
        style={{
          marginTop: 10,
          color: "white",
          padding: 10,
          // textAlign: "justify",
          lineHeight: "1.5",
        }}
      >
        Ισότιμη συμμετοχή σε έναν ευρύτερο δημόσιο διάλογο
      </p>
      <button
        type={"button"}
        style={{
          width: 180,
          height: 60,
          marginLeft: "calc((100% - 180px ) /2)",
          marginTop: 20,
          borderRadius: "30px",
          border: "none",
          cursor: "pointer",
        }}
        onClick={() => window.open("https://diavouleuseis.zitsa.gov.gr", "_blank")}
      >
        <span style={{ fontWeight: "700", fontSize: "16px" }}>Είσοδος</span>
      </button>
    </Fragment>
  );
};
export default Diav;
