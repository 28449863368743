import React from "react";
import classes from "./ChartLine.module.css";
import "chart.js/auto";
import { Bar, Line } from "react-chartjs-2";
import { useEffect, useState } from "react";

const data = {
  labels: [
    "00:00",
    "01:00",
    "02:00",
    "03:00",
    "04:00",
    "05:00",
    "06:00",
    "07:00",
    "08:00",
    "09:00",
    "10:00",
    "11:00",
    "12:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
    "18:00",
    "19:00",
    "20:00",
    "21:00",
    "22:00",
    "23:00",
  ],
  datasets: [
    {
      label: "",
      data: [],
      fill: false,
      backgroundColor: "rgb(255, 99, 132)",
      borderColor: "rgba(255, 99, 132, 0.2)",
    },
  ],
};

const options = {
  responsive: true,
  // scales: {
  //   yAxes: [
  //     {
  //       ticks: {
  //         beginAtZero: true,
  //       },
  //     },
  //   ],
  // },
};

const ChartLine = (props) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [chartData, setChartData] = useState(data);


  useEffect(() => {
    if (props.data && props.data.details.length > 0) {
      //props.data.details[0].datetime;
      let startDateId = 0;
      let endDateId = 0;
      for (let i = 1; i < props.data.details.length; i++) {
        if (
          new Date(props.data.details[i].date_insert) <=
          new Date(props.data.details[startDateId].date_insert)
        )
          startDateId = i;
        if (
          new Date(props.data.details[i].date_insert) >=
          new Date(props.data.details[endDateId].date_insert)
        )
          endDateId = i;
      }

      setStartDate(props.data.details[startDateId].datetime);
      setEndDate(props.data.details[endDateId].datetime);

      console.log(props.type);

      if (!("datetime" in props.data.details[0])) return;

      if (props.type <= 2) {
        const currChartData = props.data.details.map((item) => {
          const time = item.datetime.split(" ")[1].split(":");
          return { x: time[0] + ":" + time[1], y: item.val };
        });

        setChartData((prevChartData) => {
          return {
            ...prevChartData,
            datasets: [
              {
                ...prevChartData.datasets[0],
                data: currChartData,
                label: props.data.description,
              },
            ],
          };
        });
      } else {
        const currChartData = props.data.details.map((item) => {
          const time = item.datetime.split("/");
          return { x: time[0] + "/" + time[1], y: item.val };
        });

        const currDates = currChartData.map((item) => item.x);

        setChartData((prevChartData) => {
          return {
            ...prevChartData,
            datasets: [
              {
                ...prevChartData.datasets[0],
                data: currChartData,
                label: props.data.description,
              },
            ],
            labels: currDates,
          };
        });
      }
    }
  }, [props.data]);

  return (
    <section className={classes.graph}>
      <h1 className={classes.graph_title}>{props.data.description}</h1>
      <div
        className={
          props.size === "small" ? classes.labels_small : classes.labels
        }
      >
        <span>
          <h2 className={classes.space_bottom}>Ελάχιστη Τιμή</h2>
          <h3>
            {props.data.dec
              ? parseFloat(props.data.min).toFixed(props.data.dec)
              : parseFloat(props.data.min).toFixed(1)}
            {props.data.unit && " " + props.data.unit}
          </h3>
        </span>
        <span>
          <h2 className={classes.space_bottom}>Μέσος Όρος</h2>
          <h3>
            {props.data.dec
              ? parseFloat(props.data.avg).toFixed(props.data.dec)
              : parseFloat(props.data.avg).toFixed(1)}
            {props.data.unit && " " + props.data.unit}
          </h3>
        </span>
        <span>
          <h2 className={classes.space_bottom}>Μέγιστη Τιμή</h2>
          <h3>
            {props.data.dec
              ? parseFloat(props.data.max).toFixed(props.data.dec)
              : parseFloat(props.data.max).toFixed(1)}
            {props.data.unit && " " + props.data.unit}
          </h3>
        </span>
      </div>
      {props.type <= 2 && (
        <Line
          data={chartData}
          options={{
            ...options,
            plugins: {
              tooltip: {
                callbacks: {
                  label: function (tooltipItem) {
                    return props.data.unit
                      ? " " +
                          parseFloat(tooltipItem.raw.y).toFixed(1) +
                          " " +
                          props.data.unit
                      : " " + parseFloat(tooltipItem.raw.y).toFixed(1);
                  },
                },
              },
            },
          }}
          className={classes.graph_line}
        />
      )}

      {props.type > 2 && (
        <Bar
          data={chartData}
          options={{
            ...options,
            plugins: {
              tooltip: {
                callbacks: {
                  label: function (tooltipItem) {
                    return props.data.unit
                      ? " " +
                          parseFloat(tooltipItem.raw.y).toFixed(1) +
                          " " +
                          props.data.unit
                      : " " + parseFloat(tooltipItem.raw.y).toFixed(1);
                  },
                },
              },
            },
          }}
          className={classes.graph_line}
        />
      )}

      <div className={classes.period}>
        {props.type > 2 && (
          <>
            <i>{startDate}</i> &nbsp; - &nbsp; <i>{endDate}</i>{" "}
          </>
        )}
        {props.type <= 2 && (
          <>
            <i>{startDate}</i>
          </>
        )}
      </div>
    </section>
  );
};

export default ChartLine;
