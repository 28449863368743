import React from "react";
import classes from "./MemorandumsBinRounds.module.css";

const MemorandumBinRounds = () => {
  return (
    <div className={classes.round_bins}>
      <div>
        <h1>Αποκομιδές</h1>
        <p>#501</p>
      </div>
      <div>
        <h1>1</h1>
        <p>#1</p>
      </div>
      <div>
        <h1>2</h1>
        <p>#137</p>
      </div>
      <div>
        <h1>3</h1>
        <p>#222</p>
      </div>
      <div>
        <h1>4</h1>
        <p>#118</p>
      </div>
      <div>
        <h1>5</h1>
        <p>#18</p>
      </div>
      <div>
        <h1>6</h1>
        <p>#4</p>
      </div>
      <div>
        <h1>7</h1>
        <p>#1</p>
      </div>
      <div>
        <h1>8</h1>
        <p>#0</p>
      </div>
      <div>
        <h1>9</h1>
        <p>#0</p>
      </div>
      <div>
        <h1>10</h1>
        <p>#0</p>
      </div>
    </div>
  );
};

export default MemorandumBinRounds;
