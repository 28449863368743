import React from "react";
import classes from "./LegendFixed.module.css";

import { Fragment, useState } from "react";

import AlertNotAnswerdCall from "./../../../assets/images/alerts/NotAnswerdCall.png";
import AlertAnswredCall from "./../../../assets/images/alerts/Telephone.png";
import AlertEmail from "./../../../assets/images/alerts/Email.png";
import AlertSms from "./../../../assets/images/alerts/Sms.png";
import AlertHoldNotification from "./../../../assets/images/alerts/HoldNotification.png";
import InformationLegend from "./../../../assets/images/alerts/Information.png";

const LegendFixed = () => {
  const [close, setClose] = useState(false);
  return (
    <Fragment>
      <ul
        onClick={() => {
          if (close) setClose(false);
        }}
        style={close ? { cursor: "pointer" } : null}
        className={
          close
            ? [
                classes.legend_fixed_container,
                classes.close_legend_container,
              ].join(" ")
            : classes.legend_fixed_container
        }
      >
        {!close && (
          <>
            <li>
              <img src={AlertNotAnswerdCall} alt={"icon"} />
              <span>Αναπάντητη κλήση</span>
            </li>
            <li>
              <img src={AlertAnswredCall} alt={"icon"} />
              <span>Πραγματοποιήθηκε κλήση</span>
            </li>
            <li>
              <img src={AlertEmail} alt={"icon"} />

              <span>Αποστολή τουλάχιστον ενός email</span>
            </li>
            <li>
              <img src={AlertSms} alt={"icon"} />
              <span>Αποστολή τουλάχιστον ενός SMS</span>
            </li>
            <li>
              <img src={AlertHoldNotification} alt={"icon"} />
              <span>Παρακαλούθηση ειδοποίησης</span>
            </li>

            <span onClick={() => setClose(true)} className={classes.close_btn}>
              &#10006;
            </span>
          </>
        )}

        {close && (
          <>
            <img src={InformationLegend} alt={"icon"} />
          </>
        )}
      </ul>
    </Fragment>
  );
};

export default LegendFixed;
