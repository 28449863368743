import React from "react";
import classes from "./SmallGraph.module.css";
import SmallStatistics from "../../vertical/SmallFastStatistics/SmallStatistics";
import ChartLine from "../../chartsjs/ChartLine";
import { InputLabel, MenuItem, Select,FormControl,FormLabel  } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useStore } from "../../../../hooks/store";
import { useEffect, useState, useRef } from "react";

import MemorandumBinRounds from "./Memorandums/MemorandumBinRounds";
import MemorandumEnviValues from "./Memorandums/MemorandumEnviValues";

const baseUrl = "https://zitsa.smartiscity.gr";
const contentUrl = "/api/api.php";
const actionUrl = "func=stats";

const useStyles = makeStyles((theme) => ({
  selectEmpty: {
    "& .Mui-disabled": {
      color: "rgba(255, 255, 255, 0.4)",
    },
    marginTop: theme.spacing(2),
    width: "100%",
    color: "black",
    textAlign: "left",
    paddingLeft: "5px",
    "&:before": {
      borderBottom: "2px solid rgb(92, 99, 106)",
    },
    "&:hover:before": {
      borderBottom: "4px solid rgb(92, 99, 106) !important",
    },
    "&:after": {
      borderBottom: "4px solid rgb(92, 99, 106)",
    },
    "& .MuiSelect-icon": {
      color: "black",
    },
  },
}));

const SmallGraph = (props) => {
  const state = useStore()[0];
  const classes_select = useStyles();

  const categoryName = useRef({ single: "", plural: "" });

  const [binsList, setBinsList] = useState([]);
  const [enviList, setEnviList] = useState([]);

  const [percBins, setPercBins] = useState([]); // 90%, 75%, 55%, 15%, 0%
  const [enviPM10, setEnviPM10] = useState([]);
  const [enviPM2, setEnviPM2] = useState([]);

  const [data, setData] = useState([]);

  const [activeOption, setActiveOption] = useState(0);
  const [selectList, setSelectList] = useState([]);

  const controller = new AbortController();
  const { signal } = controller;

  useEffect(() => {
    setBinsList(state.sensors.filter((item) => item.typeId === "9"));
    setEnviList(state.sensors.filter((item) => item.typeId === "1"));
  }, [state]);

  useEffect(() => {
    if (binsList.length > 0) {
      let avg = 0;
      let min = 100;
      let max = 0;
      const fullBins = [];
      const bins = [];
      const halfBins = [];
      const lowBins = [];
      const emptyBins = [];
      binsList.map((bin) => {
        bin.metrics.map((item) => {
          if (item.content === "Ποσοστό πληρότητας κάδου") {
            const value = parseFloat(item.value.replace("%", ""));

            if (value > 90) fullBins.push(bin);
            else if (value > 75) bins.push(bin);
            else if (value > 55) halfBins.push(bin);
            else if (value > 15) lowBins.push(bin);
            else emptyBins.push(bin);

            avg += value;
            if (value > max) max = value;
            if (value < min) min = value;
          }
        });
      });

      const garbages = [];
      garbages.push(fullBins, bins, halfBins, lowBins, emptyBins);
      setPercBins(garbages);

      const statisticData = {
        name: "Κάδοι",
        description: "Πληρότητα",
        unit: "%",
        avg: (avg / binsList.length).toFixed(2),
        min: min.toFixed(2),
        max: max.toFixed(2),
        details: [
          {
            date_insert: "2021-11-28",
            hour_insert: "23",
            datetime: "28/11/2021 23:00:00",
            val: avg / binsList.length,
          },
          {
            date_insert: "2021-11-28",
            hour_insert: "22",
            datetime: "28/11/2021 22:00:00",
            val: avg / binsList.length,
          },
          {
            date_insert: "2021-11-28",
            hour_insert: "21",
            datetime: "28/11/2021 21:00:00",
            val: avg / binsList.length,
          },
          {
            date_insert: "2021-11-28",
            hour_insert: "20",
            datetime: "28/11/2021 20:00:00",
            val: avg / binsList.length,
          },
          {
            date_insert: "2021-11-28",
            hour_insert: "19",
            datetime: "28/11/2021 19:00:00",
            val: avg / binsList.length,
          },
          {
            date_insert: "2021-11-28",
            hour_insert: "18",
            datetime: "28/11/2021 18:00:00",
            val: avg / binsList.length,
          },
          {
            date_insert: "2021-11-28",
            hour_insert: "17",
            datetime: "28/11/2021 17:00:00",
            val: avg / binsList.length,
          },
          {
            date_insert: "2021-11-28",
            hour_insert: "16",
            datetime: "28/11/2021 16:00:00",
            val: avg / binsList.length,
          },
          {
            date_insert: "2021-11-28",
            hour_insert: "15",
            datetime: "28/11/2021 15:00:00",
            val: avg / binsList.length,
          },
          {
            date_insert: "2021-11-28",
            hour_insert: "14",
            datetime: "28/11/2021 14:00:00",
            val: avg / binsList.length,
          },
          {
            date_insert: "2021-11-28",
            hour_insert: "13",
            datetime: "28/11/2021 13:00:00",
            val: avg / binsList.length,
          },
          {
            date_insert: "2021-11-28",
            hour_insert: "12",
            datetime: "28/11/2021 12:00:00",
            val: avg / binsList.length,
          },
          {
            date_insert: "2021-11-28",
            hour_insert: "11",
            datetime: "28/11/2021 11:00:00",
            val: avg / binsList.length,
          },
          {
            date_insert: "2021-11-28",
            hour_insert: "10",
            datetime: "28/11/2021 10:00:00",
            val: avg / binsList.length,
          },
          {
            date_insert: "2021-11-28",
            hour_insert: "9",
            datetime: "28/11/2021 09:00:00",
            val: avg / binsList.length,
          },
          {
            date_insert: "2021-11-28",
            hour_insert: "8",
            datetime: "28/11/2021 08:00:00",
            val: avg / binsList.length,
          },
          {
            date_insert: "2021-11-28",
            hour_insert: "7",
            datetime: "28/11/2021 07:00:00",
            val: avg / binsList.length,
          },
          {
            date_insert: "2021-11-28",
            hour_insert: "6",
            datetime: "28/11/2021 06:00:00",
            val: avg / binsList.length,
          },
          {
            date_insert: "2021-11-28",
            hour_insert: "5",
            datetime: "28/11/2021 05:00:00",
            val: avg / binsList.length,
          },
          {
            date_insert: "2021-11-28",
            hour_insert: "4",
            datetime: "28/11/2021 04:00:00",
            val: avg / binsList.length,
          },
          {
            date_insert: "2021-11-28",
            hour_insert: "3",
            datetime: "28/11/2021 03:00:00",
            val: avg / binsList.length,
          },
          {
            date_insert: "2021-11-28",
            hour_insert: "2",
            datetime: "28/11/2021 02:00:00",
            val: avg / binsList.length,
          },
          {
            date_insert: "2021-11-28",
            hour_insert: "1",
            datetime: "28/11/2021 01:00:00",
            val: avg / binsList.length,
          },
          {
            date_insert: "2021-11-28",
            hour_insert: "0",
            datetime: "28/11/2021 00:00:00",
            val: avg / binsList.length,
          },
        ],
      };
      // setData(statisticData);
    }
  }, [binsList]);

  useEffect(() => {
    if (enviList.length > 0) {
      let avgPM10 = 0;
      let minPM10 = 100;
      let maxPM10 = 0;
      const pm10_0_20 = [];
      const pm10_20_40 = [];
      const pm10_40_50 = [];
      const pm10_50_100 = [];
      const pm10_100_150 = [];
      const pm10_150_1200 = [];

      let avgPM2 = 0;
      let minPM2 = 100;
      let maxPM2 = 0;
      const pm2_0_10 = [];
      const pm2_10_20 = [];
      const pm2_20_25 = [];
      const pm2_25_50 = [];
      const pm2_50_75 = [];
      const pm2_75_800 = [];
      enviList.map((envi) => {
        envi.metrics.map((item) => {
          if (item.content === "Σωματίδια σκόνης PM10") {
            const value = parseFloat(item.value);
            console.log(value);
            if (value > 150) pm10_150_1200.push(envi);
            else if (value > 100) pm10_100_150.push(envi);
            else if (value > 50) pm10_50_100.push(envi);
            else if (value > 40) pm10_40_50.push(envi);
            else if (value > 20) pm10_20_40.push(envi);
            else pm10_0_20.push(envi);

            if (value < minPM10) minPM10 = value;
            else if (value > maxPM10) maxPM10 = value;
            avgPM10 += value;
          } else if (item.content === "Σωματίδια σκόνης PM2.5") {
            const value = parseFloat(item.value);
            console.log(value);
            if (value > 75) pm2_75_800.push(envi);
            else if (value > 50) pm2_50_75.push(envi);
            else if (value > 25) pm2_25_50.push(envi);
            else if (value > 20) pm2_20_25.push(envi);
            else if (value > 10) pm2_10_20.push(envi);
            else pm2_0_10.push(envi);

            if (value < minPM2) minPM2 = value;
            else if (value > maxPM2) maxPM2 = value;
            avgPM2 += value;
          }
        });
      });

      const pm10Full = [];
      pm10Full.push(
        pm10_150_1200,
        pm10_100_150,
        pm10_50_100,
        pm10_40_50,
        pm10_20_40,
        pm10_0_20
      );
      console.log(pm10Full);
      setEnviPM10(pm10Full);

      const pm2Full = [];
      pm2Full.push(
        pm2_75_800,
        pm2_50_75,
        pm2_25_50,
        pm2_20_25,
        pm2_10_20,
        pm2_0_10
      );
      console.log(pm2Full);
      setEnviPM2(pm2Full);
    }
  }, [enviList]);

  useEffect(() => {
    if (props.typeId === "1")
      categoryName.current = {
        single: "Περιβαλλοντικός",
        plural: "Περιβαλλοντικοί",
      };
    else if (props.typeId === "9")
      categoryName.current = { single: "Κάδος", plural: "Κάδοι" };
  }, [props.typeId]);

  useEffect(() => {
    if (props.typeId === "1") {
      setActiveOption(0);
      setSelectList(enviList);
    } else if (props.typeId === "9") {
      setActiveOption(0);
      setSelectList(binsList);
    } else setSelectList([]);
  }, [binsList, enviList, props.typeId]);

  useEffect(() => {
    getStats().catch((error) => console.warn(error));
  }, [activeOption]);

  const handleChange = (event) => {
    setActiveOption(event.target.value);
  };

  const getStats = async () => {
    let sensorId = `&category=${props.typeId}`;
    if (activeOption !== 0) sensorId = `&sensor=${activeOption}`;
    const response = await fetch(
      `${baseUrl}${contentUrl}?${actionUrl}${sensorId}&period=${1}`,
      { signal }
    ).catch((error) => console.warn(error));
    if (response === undefined || !response.ok) {
      return;
    }

    const data = await response.json();
    if (props.typeId === "1") {
      const statsData = data.filter((item) =>
        props.loop.includes(item.description)
      );
      setData(statsData);
      console.log(statsData);
    } else {
      setData([]);
    }
  };

  console.log(percBins);

  return (
    <div className={classes.small_graph_container}>
      <div style={{ maxWidth: "50%", margin: "20px auto 20px" }}>
   
      <FormControl>

      <FormLabel id="select-field-demo-label" htmlFor="uncontrolled-native">
      <InputLabel
          style={{
            margin: "0 auto",
            textAlign: "left",
            color: "black",
          }}
        >
          {categoryName.current.single}
        </InputLabel>
      </FormLabel>
        <Select
          className={classes_select.selectEmpty}
          value={activeOption}
          onChange={handleChange}
          inputProps={{
            name: 'var',
            id: 'uncontrolled-native',
            title: 'title'
          }}
        
        >
          
          <MenuItem
            value={0}
            title='123'
          >{`Όλοι οι ${categoryName.current.plural}`}</MenuItem>
          
          {selectList.map((item, index) => (
            <MenuItem key={index} value={item.id}  title={item.id} >
              {item.title}
            </MenuItem>
           
          ))}
           
        </Select>
        </FormControl>
      </div>
      {props.typeId === "9" && (
        <div style={{ padding: "0 10px" }}>
          {/*{data && <ChartLine data={data} type={1} />}*/}
        </div>
      )}
      {props.typeId === "1" && (
        <div style={{ padding: "0 10px" }}>
          {data.map((item, index) => (
            <ChartLine key={index} data={item} type={1} />
          ))}
          {/*{data && (*/}
          {/*  <>*/}
          {/*    <ChartLine data={data} type={1} />*/}
          {/*    <ChartLine data={data} type={1} />*/}
          {/*    <ChartLine data={data} type={1} />*/}
          {/*  </>*/}
          {/*)}*/}
          {/*{data.map((item) => (*/}
          {/*  <div>test</div>*/}
          {/*  <ChartLine data={item} type={1} />*/}
          {/*))}*/}
        </div>
      )}

      {props.typeId === "9" && (
        <>
          <MemorandumEnviValues
            values={percBins}
            title={categoryName.current.plural}
            labels={["90%", "75%", "55%", "15%", "0%"]}
          />
          {/*<MemorandumBinRounds />*/}
        </>
      )}

      {props.typeId === "1" && (
        <>
          <MemorandumEnviValues
            values={enviPM10}
            title={"pm10"}
            labels={["50-1200", "100-150", "50-100", "40-50", "20-40", "0-20"]}
          />

          <MemorandumEnviValues
            values={enviPM2}
            title={"pm2.5"}
            labels={["75-800", "50-75", "25-50", "20-25", "10-20", "0-10"]}
          />
        </>
      )}
    </div>
  );
};

export default SmallGraph;
