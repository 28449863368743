import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";

const StyledTextField = withStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    width: "70%",
    "& .MuiInput-underline": {
      "&:before": {
        borderBottom: "2px solid rgb(48, 48, 48)",
      },
      "&:hover:before": {
        borderBottom: "4px solid  rgb(48, 48, 48)",
      },
      "&:after": {
        borderBottom: "4px solid rgb(199, 199, 199)",
      },
      color: "white",
      minHeight: 60,
      "& input": {
        textAlign: "left",
        paddingLeft: "5px",
      },
      "& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
      },
      "& input[type=number]": {
        "-moz-appearance": "textfield",
      },
    },
    "& .MuiFormLabel-root": {
      color: "white",
      fontSize: "16px",
    },
  },
}))(TextField);

export const StyledTextFieldContrast = withStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    width: "90%",
    "& .MuiInput-underline": {
      "&:before": {
        borderBottom: "2px solid rgb(92, 99, 106)",
      },
      "&:hover:before": {
        borderBottom: "4px solid rgb(92, 99, 106)",
      },
      "&:after": {
        borderBottom: "4px solid rgb(55, 59, 64)",
      },
      color: "rgb(55,59,64)",
      "& input": {
        textAlign: "left",
        paddingLeft: "5px",
      },
      "& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
      },
      "& input[type=number]": {
        "-moz-appearance": "textfield",
      },
    },
    "& .MuiFormLabel-root": {
      color: "rgb(55, 59, 64)",
    },
  },
}))(TextField);

export default StyledTextField;
