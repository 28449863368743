import React from "react";
import classes from "./VerticalInfoContent.module.css";
import Entity from "../../UI/box/entity/Entity";

const VerticalInfoContent = (props) => {
  return (
    <Entity
      key={1}
      icon={null}
      content={"TEST VALUE"}
      value={123}
      className={classes.gradient_border}
      styleValue={{ fontWeight: "400" }}
    />
  );
};

export default VerticalInfoContent;
