import classes from "./Telephone.module.css";
import Autocomplete from "@material-ui/lab/Autocomplete";
import StyledTextField from "../../UI/input/StyleTextField";
import Card from "../../UI/cards/Card";
import React, { useEffect, useRef, useState } from "react";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";

const top100Films = [
  { name: "The Shawshank Redemption", number: "1994" },
  { name: "The Godfather", number: "1972" },
  { name: "The Godfather: Part II", number: "1974" },
];

const CustomCheckBox = withStyles({
  root: {
    color: grey[50],
    "&$checked": {
      color: grey[50],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const Telephone = (props) => {
  const { alertDetails } = props;
  const [accountableName, setAccountableName] = useState(null);
  const [answered, setAnswered] = useState(true);
  const [listAccountableNames, setListAccountableNames] = useState([]);

  useEffect(() => {
    if (alertDetails) {
      setListAccountableNames([
        { name: alertDetails.mailto, number: "69X-XXXX-XXXX" },
      ]);
    }
  }, [alertDetails]);

  const submit = async () => {
    const url = answered
      ? `https://zitsa.smartiscity.gr/api/api.php?func=alertaction&alert_id=${alertDetails.alert_id}&action_id=2`
      : `https://zitsa.smartiscity.gr/api/api.php?func=alertaction&alert_id=${alertDetails.alert_id}&action_id=1`;

    const postAction = await fetch(url, { method: "POST" }).catch((error) =>
      console.warn(error)
    );

    if (postAction === undefined || !postAction.ok) return;

    const data = await postAction.json();
    console.log(accountableName, answered);
    console.log(data);
    clear();
  };

  const clear = () => {
    setAccountableName(null);
    setAnswered(true);
  };

  console.log(alertDetails);

  return (
    <Card
      title={"Τηλεφωνική Επικοινωνία"}
      id={"telephoneForm"}
      onCancel={clear}
      onConfirm={submit}
      historyTitle={props.historyTitle}
      historyList={
        alertDetails.history
          ? alertDetails.history.filter(
              (item) => item.action_id === "1" || item.action_id === "2"
            )
          : []
      }
      confirmNameBtn={"Επιβεβαίωση"}
    >
      <Autocomplete
        style={{ width: "70%", marginTop: "40px" }}
        id="user-points"
        options={listAccountableNames}
        getOptionLabel={(option) => option.name}
        onChange={(event, selectedValue) => setAccountableName(selectedValue)}
        value={accountableName}
        renderInput={(listAccountableNames) => (
          <StyledTextField
            {...listAccountableNames}
            id="accountableName"
            label="Στοιχεία Υπεύθυνου"
            type="text"
            style={{ width: "100%", marginLeft: "0px" }}
            required
          />
        )}
      />
      <StyledTextField
        style={{ width: "70%", marginLeft: "0px" }}
        id="accountableTelephone"
        label="Τηλέφωνο"
        type="text"
        required
        disabled
        onChange={(event) => setAccountableName(event.target.value)}
        value={accountableName ? accountableName.number : ""}
      />
      <FormControlLabel
        style={{ marginTop: "10px" }}
        control={
          <CustomCheckBox
            checked={answered}
            onChange={() =>
              setAnswered((prev) => {
                return !prev;
              })
            }
          />
        }
        label="Απάντησε"
        labelPlacement="start"
      />
    </Card>
  );
};

export default Telephone;
