import classes from "./Sms.module.css";
import Card from "../../UI/cards/Card";
import React, { useEffect, useRef, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import StyledTextField from "../../UI/input/StyleTextField";

const top100Films = [
  { name: "The Shawshank Redemption", number: "1994" },
  { name: "The Godfather", number: "1972" },
  { name: "The Godfather: Part II", number: "1974" },
];

const Sms = (props) => {
  const { alertDetails } = props;
  const [accountableName, setAccountableName] = useState(null);
  const [listAccountableNamesList, setAccountableNamesList] = useState([]);
  const body = useRef(null);

  useEffect(() => {
    if (alertDetails) {
      setAccountableNamesList([{ name: alertDetails.mailto }]);
    }
  }, [alertDetails]);

  const clear = () => {
    body.current.value = "";
    setAccountableName(null);
  };

  const submit = async () => {
    const postAction = await fetch(
      `https://zitsa.smartiscity.gr/api/api.php?func=alertaction&alert_id=${alertDetails.alert_id}&action_id=4`,
      { method: "POST" }
    ).catch((error) => console.warn(error));

    if (postAction === undefined || !postAction.ok) return;

    const data = await postAction.json();
    console.log(accountableName, body.current.value);
    console.log(data);
    clear();
  };

  return (
    <Card
      title={"Υπηρεσία Σύντομου Μηνύματος"}
      id={"smsForm"}
      onCancel={clear}
      onConfirm={submit}
      historyTitle={props.historyTitle}
      historyList={
        alertDetails.history
          ? alertDetails.history.filter((item) => item.action_id === "4")
          : []
      }
    >
      <Autocomplete
        style={{ width: "70%", marginTop: "40px" }}
        id="user-points"
        options={listAccountableNamesList}
        getOptionLabel={(option) => option.name}
        onChange={(event, selectedValue) => setAccountableName(selectedValue)}
        value={accountableName}
        renderInput={(listAccountableNamesList) => (
          <StyledTextField
            {...listAccountableNamesList}
            id="accountableName"
            label="Στοιχεία Υπεύθυνου"
            type="text"
            style={{ width: "100%", marginLeft: "0px" }}
            required
          />
        )}
      />

      <StyledTextField
        id="title"
        label="Περιγραφή ενέργειας"
        type="text"
        multiline
        rows={5}
        variant="standard"
        required
        style={{ width: "70%" }}
        inputRef={body}
      />
    </Card>
  );
};

export default Sms;
