import React from "react";
import classes from "./VerticalAlertContent.module.css";

const VerticalAlertContent = ({ alertDetails }) => {
  return (
    <div className={classes.vertical_content_container}>
      <h1>ΕΙΔΟΠΟΙΗΣΕΙΣ</h1>
      <ul>
        <li>
          {alertDetails.alert_name} - {alertDetails.alert_object}
        </li>
      </ul>
    </div>
  );
};

export default VerticalAlertContent;
