import { Fragment, useState } from "react";
import React from "react";
import classes from "./Horizontal.module.css";
import SearchSubSystem from "./../../subsystems/SearchSubSystem";
import SubSystemList from "../../subsystems/SubSystemList";

const Horizontal = (props) => {
  const [searchInput, setSearchInput] = useState("");
  const [filtersVars, setFiltersVars] = useState([]);

  const varsHandler = (variable) => {
    if (!filtersVars.includes(variable))
      setFiltersVars((prev) => prev.concat(variable));
    else setFiltersVars((prev) => prev.filter((item) => item !== variable));
  };

  const clearVarsHandler = () => {
    setFiltersVars([]);
  };

  return (
    <Fragment>
      <SearchSubSystem
        close={props.close}
        findTypeName={setSearchInput}
        setVars={varsHandler}
        filtersVars={filtersVars}
        clearVars={clearVarsHandler}
      />
      <SubSystemList
        showAnimation={props.showAnimation}
        filterTypeName={searchInput}
        filtersVars={filtersVars}
      />
    </Fragment>
  );
};

export default Horizontal;
