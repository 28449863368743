import { withStyles } from "@material-ui/core/styles";
import { KeyboardDatePicker } from "@material-ui/pickers";

const StyledKeyboardDatePicker = withStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    width: "234px",
    "& .MuiInput-underline": {
      "&:before": {
        borderBottom: "2px solid rgb(69, 74,81)",
      },
      "&:hover:before": {
        borderBottom: "4px solid  rgb(69, 74, 81)",
      },
      "&:after": {
        borderBottom: "4px solid rgb(69, 74, 81)",
      },
      color: "white",
      minHeight: 60,
      "& input": {
        textAlign: "left",
        paddingLeft: "5px",
      },
      "& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
      },
      "& input[type=number]": {
        "-moz-appearance": "textfield",
      },
    },
    "& .MuiFormLabel-root": {
      color: "white",
      fontSize: "18px",
    },
    "& .MuiIconButton-label": {
      color: "white !important",
    },
    "& #date-picker-dialog": {
      fontSize: "18px",
    },
  },
}))(KeyboardDatePicker);

export default StyledKeyboardDatePicker;
