import React, { useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import Header from "./components/layout/header/Header";
import Home from "./pages/Home";
import Footer from "./components/layout/footer/Footer";
import "./App.css";
import Map from "./pages/Map";
import TermsOfUse from "./pages/TermsOfUSe";
import Frame from "./pages/Frame";
import { useStore } from "./hooks/store";
import { feedTypes, feedSensors } from "./services/feed";
import HorizontalGraph from "./components/forms/horizontal/HorizontalGraph";
import AlertsPlaceholder from "./containers/alerts_placeholder/AlertsPlaceholder";
import Alerts from "./pages/Alerts";
import Log from "./pages/Log";

const baseUrl = "https://zitsa.smartiscity.gr";
const enviURL = baseUrl + "/api/api.php?func=envi";
const meshURL = baseUrl + "/api/api.php?func=mesh";
const meteoURL = baseUrl + "/api/api.php?func=meteo";
const waterURL = baseUrl + "/api/api.php?func=waterq";
const poiURL = baseUrl + "/api/api.php?func=pois&lang=el";

const App = () => {
  const dispatch = useStore(false)[1];

  const controller = new AbortController();
  const { signal } = controller;

  useEffect(() => {
    const feed_types_call = async () => {
      const data = await feedTypes(signal);
      dispatch("INITIALIZE_TYPES", data);
    };
    const feed_sensors = async () => {
      const data = await feedSensors(signal);
      dispatch("INITIALIZE_SENSORS", data);
      feedMetrics();
    };

    feed_types_call();
    feed_sensors();

    return () => controller.abort();
  }, []);

  const feedMetrics = () => {
    feedEnvironment_Meteo(enviURL);
    feedMeshlium();
    feedEnvironment_Meteo(meteoURL);
    // feedEnvironment_Meteo(waterURL);
    // feedPOIS();
    feedFleeto();
    feedBins();
    feedHydro();
  };

  const feedEnvironment_Meteo = async (url) => {
    const req = await fetch(url, { signal }).catch((error) =>
      console.warn(error)
    );
    if (req === undefined || !req.ok) return [];
    const response = await req.json();
    console.log(response);

    let data = {};
    for (let i = 0; i < response.length; i++) {
      const metrics = [];
      response[i].details.map((item) => {
        let dec = 0;
        let symbol = "";
        if (item.sensorvar_dec) dec = parseInt(item.sensorvar_dec);
        if (item.sensorvar_unit) symbol = " " + item.sensorvar_unit;

        let value = parseFloat(item.measurement).toFixed(dec) + symbol;
        if (isNaN(item.measurement)) {
          value = item.measurement;
        }

        metrics.push({
          parentId: response[i].mysensor_id,
          content: item.sensorvar_description,
          value: value,
          valid: "valid" in item ? item.valid : 1,
          icon_small:
            item.sensorvar_icon &&
            "https://zitsa.smartiscity.gr/gallery/vars/Small/" +
              item.sensorvar_icon,
          icon_large:
            item.sensorvar_icon &&
            "https://zitsa.smartiscity.gr/gallery/vars/Big/" +
              item.sensorvar_icon,
        });
      });
      data = {
        ...data,
        [response[i].mysensor_id]: {
          metrics: metrics,
          tooltip: response[i].tooltip,
        },
      };
    }
    dispatch("ADD_METRICS", data);
    console.log(data);
  };

  const feedMeshlium = async () => {
    const req = await fetch(meshURL, { signal }).catch((error) =>
      console.warn(error)
    );
    if (req === undefined || !req.ok) return [];
    const response = await req.json();
    console.log(response);
    let data = {};
    for (let i = 0; i < response.length; i++) {
      let metrics = [];

      response[i].details.map((item) => {
        metrics.push({
          parentId: response[i].mysensor_id,
          content: item.sensorvar_description,
          value: item.wfTotal ? item.wfTotal : item.btTotal,
          icon_small:
            item.sensorvar_icon &&
            "https://zitsa.smartiscity.gr/gallery/vars/Small/" +
              item.sensorvar_icon,
          icon_large:
            item.sensorvar_icon &&
            "https://zitsa.smartiscity.gr/gallery/vars/Big/" +
              item.sensorvar_icon,
        });
      });
      data = {
        ...data,
        [response[i].mysensor_id]: {
          metrics: metrics,
          tooltip: response[i].tooltip ? response[i].tooltip : "",
        },
      };
    }
    dispatch("ADD_METRICS", data);
    console.log(data);
  };

  const feedPOIS = async () => {
    const req = await fetch(poiURL, { signal }).catch((error) =>
      console.warn(error)
    );
    if (req === undefined || !req.ok) return [];
    const response = await req.json();
    console.log(response);
    let pois = [];
    response.map((item) => {
      pois.push({
        typeId: "7",
        id: item.id,
        categoryId: item.category_id,
        categoryName: item.category_name,
        title: item.name,
        position: { lat: parseFloat(item.lat), lng: parseFloat(item.lng) },
        address: item.address,
        smallMarker: "https://zitsa.smartiscity.gr/" + item.category_small_icon,
        bigMarker: "https://zitsa.smartiscity.gr/" + item.category_big_icon,
        thumbnail: item.main_image,
        images: item.images,
        content: item.portal_main_info,
        metrics: [{ content: item.category_name }],
        isActive: false,
      });
    });
    dispatch("ADD_SENSORS", pois);
    console.log(pois);
  };

  const feedFleeto = async () => {
    const req = await fetch(
      "https://zitsa.smartiscity.gr/api/api.php?func=fleeto",
      { signal }
    ).catch((error) => console.log(error));

    if (req === undefined || !req.ok) return [];
    const response = await req.json();
    console.log(response);

    console.log(response);
    let fleeto = [];

    response.map((item) => {
      let tempMetrics = [{ content: item.category_name }];
      if (item.speed)
        tempMetrics.push({ content: "Ταχύτητα", value: item.speed + " km/h" });
      if (item.altitude)
        tempMetrics.push({
          content: "Υψόμετρο",
          value: item.altitude + " μέτρα",
        });
      if (item.angle)
        tempMetrics.push({ content: "Γωνία", value: item.angle + " μοίρες" });
      fleeto.push({
        typeId: "8",
        id: item.id,
        categoryId: item.category_id,
        categoryName: item.category_name,
        address: item.category_name,
        title: item.name,
        position: { lat: parseFloat(item.lat), lng: parseFloat(item.lng) },
        smallMarker: "https://zitsa.smartiscity.gr/" + item.category_small_icon,
        bigMarker: "https://zitsa.smartiscity.gr/" + item.category_big_icon,
        metrics: tempMetrics,
        isActive: false,
      });
    });
    dispatch("ADD_SENSORS", fleeto);
    console.log(fleeto);
  };

  const feedBins = async () => {
    const req = await fetch(
      "https://zitsa.smartiscity.gr/api/api.php?func=bins",
      { signal }
    ).catch((error) => console.log(error));

    if (req === undefined || !req.ok) return [];
    const response = await req.json();
    console.log(response);

    console.log(response);
    let bins = [];

    response.map((item) => {
      let tempMetrics = [];
      tempMetrics.push(
	  {
          content: "",
          value: item.status === 1 ? "" : "",
        },
        
        { content: "Κωδικός αισθητήρα", value: item.mote },
        {
          content: "Τελευταία ενημέρωση",
          value: item.date.split(":")[0] + ":" + item.date.split(":")[1],
        },
        { content: "Συντεταγμένες κάδου", value: item.lat + " " + item.lng },

        { content: "Ποσοστό πληρότητας κάδου", value: item.value + "%" },
        {
          content: "Τάση μπαταρίας",
          value: item.voltage,
        },
        {
          content: "Θερμοκρασία",
          value: (
            <span>
              {item.temp} <sup>o</sup>C
            </span>
          ),
        },
		  { content: "", value: "" },
        {
          content: "Τύπος κάδου",
          value: "Μπλε ανακύκλωσης 1100 lt",
        }
      );

      bins.push({
        typeId: "9",
        id: item.id,
        categoryId: item.category_id,
        categoryName: item.category_name,

        title: item.location,
        position: { lat: parseFloat(item.lat), lng: parseFloat(item.lng) },
        smallMarker: "https://zitsa.smartiscity.gr/" + item.category_small_icon,
        bigMarker: "https://zitsa.smartiscity.gr/" + item.category_big_icon,
        metrics: tempMetrics,
        isActive: false,
      });
    });
    dispatch("ADD_SENSORS", bins);
    console.log(bins);
  };

  const feedHydro = async () => {
    const req = await fetch(
      "https://zitsa.smartiscity.gr/api/api.php?func=hydro",
      { signal }
    ).catch((error) => console.log(error));

    if (req === undefined || !req.ok) return [];
    const response = await req.json();
    console.log(response);

    console.log(response);
    let hydro = [];

    response.map((item) => {
      let tempMetrics = [];
      tempMetrics.push(
        { content: "Κωδικός αισθητήρα", value: item.mote },
        { content: "Ένδειξη", value: item.value + "κ.μ." },
        {
          content: "Κατάσταση",
          value: item.status === 1 ? "Ενεργό" : "Απεν/μένο",
        }
      );

      hydro.push({
        typeId: "10",
        id: item.id,
        categoryId: item.category_id,
        categoryName: item.category_name,
        address: item.date
          ? "Τελευταία ενημέρωση:" +
            item.date.split(":")[0] +
            ":" +
            item.date.split(":")[1]
          : "",
        title: item.location,
        position: { lat: parseFloat(item.lat), lng: parseFloat(item.lng) },
        smallMarker: "https://zitsa.smartiscity.gr/" + item.category_small_icon,
        bigMarker: "https://zitsa.smartiscity.gr/" + item.category_big_icon,
        metrics: tempMetrics,
        isActive: false,
      });
    });
    dispatch("ADD_SENSORS", hydro);
    console.log(hydro);
  };

  let history = useLocation();
  console.log(history);

  return (
    <React.Fragment>
      {history.pathname !==
        "/map_frame/36rj0ihiet/uvm5ypescz/mmw1uv8cbk/dolwmo2451" &&
        history.pathname !==
          "/stats_frame/dolwmo2451/mmw1uv8cbk/uvm5ypescz/36rj0ihiet" &&
        history.pathname !==
          "/alerts_frame/uvm5ypescz/36rj0ihiet/dolwmo2451/mmw1uv8cbk" &&
        history.pathname !== "/log" && <Header />}
      <main
        style={
          history.pathname ===
            "/map_frame/36rj0ihiet/uvm5ypescz/mmw1uv8cbk/dolwmo2451" ||
          history.pathname ===
            "/stats_frame/dolwmo2451/mmw1uv8cbk/uvm5ypescz/36rj0ihiet" ||
          history.pathname ===
            "/alerts_frame/uvm5ypescz/36rj0ihiet/dolwmo2451/mmw1uv8cbk" ||
          history.pathname === "/log"
            ? {
                marginTop: 0,
                marginBottom: 0,
                height: "100%",
              }
            : {}
        }
      >
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/terms-of-use" component={TermsOfUse} />
          <Route path="/map" component={Map} />
          <Route path="/frame" component={Frame} />
          <Route
            path="/map_frame/36rj0ihiet/uvm5ypescz/mmw1uv8cbk/dolwmo2451"
            exact
          >
            <Map mapFrame={true} />
          </Route>
          <Route
            path="/stats_frame/dolwmo2451/mmw1uv8cbk/uvm5ypescz/36rj0ihiet"
            component={HorizontalGraph}
            exact
          />
          <Route
            path="/alerts_frame/uvm5ypescz/36rj0ihiet/dolwmo2451/mmw1uv8cbk"
            component={Alerts}
            exact
          />
          <Route path="/log" component={Log} exact />
        </Switch>
      </main>
      {history.pathname !==
        "/map_frame/36rj0ihiet/uvm5ypescz/mmw1uv8cbk/dolwmo2451" &&
        history.pathname !==
          "/stats_frame/dolwmo2451/mmw1uv8cbk/uvm5ypescz/36rj0ihiet" &&
        history.pathname !==
          "/alerts_frame/uvm5ypescz/36rj0ihiet/dolwmo2451/mmw1uv8cbk" &&
        history.pathname !== "/log" && <Footer />}
    </React.Fragment>
  );
};
export default App;
