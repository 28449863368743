import React from "react";
import classes from "./AlertCardHandler.module.css";
import AlertsForm from "./AlertsForm";
import AlertClose from "./AlertClose";

const AlertCardHandler = (props) => {
  return (
    <div
      className={
        props.isEnable
          ? [classes.card_inner, classes.rotate].join(" ")
          : classes.card_inner
      }
    >
      <div className={classes.card_front}>
        <AlertClose title={props.title} className={props.classBgColor} />
      </div>
      <div className={classes.card_back}>
        <AlertsForm
          typeId={props.typeId}
          title={props.title}
          className={props.classBgColor}
          onClick={props.close}
          showAnimation={props.showAnimation}
          closeSidePanel={props.closeSidePanel}
          alertsList={props.alertsList}
        />
      </div>
    </div>
  );
};

export default AlertCardHandler;
