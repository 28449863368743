import React from "react";
import { Fragment } from "react";
import Title from "../../UI/box/title/Title";
import SubTitle from "../../UI/box/subtitle/SubTitle";
import zitsa from '../../../assets/images/zitsaMap.png'
import { LoadingDot } from "../../UI/spinners/LoadingSpinners";

const Geospacial = (props) => {
  return (
    <Fragment>
      {props.data && (
        <Fragment>
          <Title
            title="ΓΕΩΠΥΛΗ"
            styleIcon={props.typeId === "5" && { color: "black" }}
            externalLink="https://zitsa.gis4smart.city/"
            element={
              <i
                id="draggable"
                className="im im-icon-Double-Tap"
                style={{
                  fontSize: "32px",
                  minWidth: "32px",
                  cursor: "pointer",
                }}
              />
            }
            id={props.typeId}
          />

          <img src={zitsa} style={{width: '163px', marginLeft: '35px', marginTop: '20px'}} alt="mapPin"/>
        </Fragment>
      )}

      {!props.data && <LoadingDot />}
    </Fragment>
  );
};

export default Geospacial;
