import {Fragment} from "react";
import Title from "../../../UI/box/title/Title";
import React from "react";
import classes from "./MapStore.module.css";
import {useHistory} from "react-router-dom";
import Ypodomwn from "./../../../../assets/images/Buildings.png";
import Perivallon from "./../../../../assets/images/Environment.png";
import Poleoodomias from "./../../../../assets/images/Poleodomia.png";
import Entos_ektos from "./../../../../assets/images/in_out.png";

import SvgIssues from "../../../../assets/icons/SvgIssues";
import SvgEnvironment from "../../../../assets/icons/SvgEnvironment";
import SvgInfrastructure from "../../../../assets/icons/SvgInfrastructure";
import SvgSettlement from "../../../../assets/icons/SvgSettlement";
import SvgUrbanPlanning from "../../../../assets/icons/SvgUrbanPlanning";

const MapStore = (props) => {
  const history = useHistory();
  // const frameRedirect = (id) => history.push(`/frame?id=${id}`);
  const frameRedirect = (url) =>
    window.open(url, "_blank", "noopener,noreferrer");
  return (
    <Fragment>
      <Title
        title={
          props.order === 1
            ? "ΕΦΑΡΜΟΓΕΣ ΧΑΡΤΟΓΡΑΦΗΣΗΣ"
            : "ΕΞΥΠΗΡΕΤΗΣΗ ΤΟΥ ΠΟΛΙΤΗ"
        }
        icon={"im im-icon-Map2"}
      
        infoContext={
          props.order === 1
            ? "Δείτε τα χαρτογραφικά δεδομένα της πολεοδομικής και περιβαλλοντιικής φύσης και ενημερωθείτε για το δίκτυο υποδομών του Δήμου μας."
            : "Δείτε τα χαρτογραφικά δεδομένα του δικτύου φωτισμού του Δήμου μας όπως και των γεωγραφικών ορίων των οικισμών και του Δήμου"
        }
        element={
          <i
            id="draggable"
            className="im im-icon-Double-Tap"
            style={{fontSize: "32px", minWidth: "32px", cursor: "pointer"}}
          />
        }
      />
      {props.order === 1 && (
        <ul
          className={[
            classes.iframes_container,
            classes.iframes_smaller_imgs,
          ].join(" ")}
        >
          {/* <li onClick={() => frameRedirect(1)}> */}
          <li
            onClick={() =>
              frameRedirect(
                "http://88.99.24.44:8080/zitsa/#/viewer/leaflet/553"
              )
            }
          >
            <p>Εφαρμογή υποδομών</p>
            <span style={{borderRadius: '50%', backgroundColor: 'white', padding: '3px'}}>
              <i className={'im im-icon-Map-Marker2'} style={{fontSize: '40px', fontWeight: '700'}}/>
            </span>

            {/*<SvgInfrastructure  width={50} height={50} viewBox='0 0 100 100' overflow='unset'/>*/}
          </li>
          {/* <li onClick={() => frameRedirect(2)}> */}
          <li
            onClick={() =>
              frameRedirect(
                "http://88.99.24.44:8080/zitsa/#/viewer/leaflet/568"
              )
            }
          >
            <p>Εφαρμογή περιβάλλοντος</p>
            {/*<SvgEnvironment width={50} height={50} viewBox='0 0 100 100' overflow='unset'/>*/}
            <span style={{borderRadius: '50%', backgroundColor: 'white', padding: '5.5px'}}>
              <i className={'im  im-icon-Environmental-3'} style={{fontSize: '35px', fontWeight: '700'}}/>
            </span>
          </li>
          {/* <li onClick={() => frameRedirect(3)}> */}
          <li
            onClick={() =>
              frameRedirect(
                "http://88.99.24.44:8080/zitsa/#/viewer/leaflet/491"
              )
            }
          >
            <p>Εφαρμογή πολεοδομίας</p>
            {/*<SvgUrbanPlanning  width={50} height={50} viewBox={'0 0 100 100'} />*/}
            <span style={{borderRadius: '50%', backgroundColor: 'white', padding: '6px'}}>
              <i className={'im im-icon-Edit-Map'} style={{fontSize: '34px', fontWeight: '700'}}/>
            </span>
          </li>
        </ul>
      )}

      {props.order === 2 && (
        <ul className={classes.iframes_container}>
          {/* <li onClick={() => frameRedirect(4)}> */}
          <li
            onClick={() =>
              frameRedirect(
                "http://88.99.24.44:8080/odofotismos/#/viewer/leaflet/527"
              )
            }
          >
            <p>Εφαρμογή βλαβών οδοφωτισμού</p>
            {/*<SvgIssues width='50px' height='50px' viewBox='0 0  100 100' overflow='unset'/>*/}
            <span style={{borderRadius: '50%', backgroundColor: 'white', padding: '3px'}}>
              <i className={'im   im-icon-Light-Bulb'} style={{fontSize: '40px', fontWeight: '700'}}/>
            </span>
          </li>
          {/* <li onClick={() => frameRedirect(5)}> */}
          <li
            onClick={() =>
              frameRedirect(
                "http://88.99.24.44:8080/gewtemachio/#/viewer/leaflet/519"
              )
            }
          >
            <p>Εφαρμογή βεβαίωσης εντός/εκτός οικισμού</p>
            <span style={{borderRadius: '50%', backgroundColor: 'white', padding: '5.5px'}}>
              {/*<SvgSettlement width={30} height={30} viewBox='0 0 90 90' overflow='unset' style={{transform: 'translate(-1px, -2px)'}}/>*/}
              <i className={'im  im-icon-Map-Marker3'} style={{fontSize: '35px', fontWeight: '700'}}/>
            </span>

          </li>
        </ul>
      )}
    </Fragment>
  );
};

export default MapStore;

// import { Fragment } from "react";
// import Title from "../../../UI/box/title/Title";
// import React from "react";
// import { useHistory } from "react-router-dom";
// import Ypodomwn from "./../../../../assets/images/Buildings.png";
// import Perivallon from "./../../../../assets/images/Environment.png";
// import Poleoodomias from "./../../../../assets/images/Poleodomia.png";
// import Ilektrofwtismou from "./../../../../assets/images/Electricity.png";
// import Entos_ektos from "./../../../../assets/images/in_out.png";

// const MapStore = (props) => {
//   const history = useHistory();
//   const frameRedirect = (id) => history.push(`/frame?id=${id}`);

//   return (
//     <Fragment>
//       <Title
//         title={
//           props.order === 1
//             ? "ΕΦΑΡΜΟΓΕΣ ΧΑΡΤΟΓΡΑΦΗΣΗΣ"
//             : "ΕΞΥΠΗΡΕΤΗΣΗ ΤΟΥ ΠΟΛΙΤΗ"
//         }
//         icon={"im im-icon-Map2"}
//         infoContext={"TEST"}
//         element={
//           <i
//             id="draggable"
//             className="im im-icon-Double-Tap"
//             style={{ fontSize: "32px", minWidth: "32px", cursor: "pointer" }}
//           />
//         }
//       />
//       {props.order === 1 && (
//         <ul className={classes.iframes_container}>
//           <li onClick={() => frameRedirect(1)}>
//             <p>Εφαρμογή υποδομών</p>
//             <img src={Ypodomwn} alt="frame 1" width="70px" height="70px" />
//           </li>
//           <li onClick={() => frameRedirect(2)}>
//             <p>Εφαρμογή περιβάλλοντος</p>
//             <img src={Perivallon} alt="frame 2" width="70px" height="70px" />
//           </li>
//           <li onClick={() => frameRedirect(3)}>
//             <p>Εφαρμογή πολεοδομίας</p>
//             <img src={Poleoodomias} alt="frame 3" width="70px" height="70px" />
//           </li>
//         </ul>
//       )}

//       {props.order === 2 && (
//         <ul className={classes.iframes_container}>
//           <li onClick={() => frameRedirect(4)}>
//             <p>Εφαρμογή βλαβών οδοφωτισμού</p>
//             <img
//               src={Ilektrofwtismou}
//               alt="frame 4"
//               width="70px"
//               height="70px"
//             />
//           </li>
//           <li onClick={() => frameRedirect(5)}>
//             <p>Εφαρμογή βεβαίωσης εντός/εκτός οικισμού</p>
//             <img src={Entos_ektos} alt="frame 5" width="70px" height="70px" />
//           </li>
//         </ul>
//       )}
//     </Fragment>
//   );
// };

// export default MapStore;
