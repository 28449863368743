import React from "react";
import classes from "./VerticalHistory.module.css";
import GlobalHoverCard from "../../UI/hoverCard/GlobalHoverCard";

import alertEmail from "./../../../assets/images/alerts/Email.png";
import alertSMS from "./../../../assets/images/alerts/Sms.png";
import alertCall from "./../../../assets/images/alerts/Telephone.png";

const VerticalHistory = () => {
  return (
    <div className={classes.vertical_history_container}>
      <div className={[classes.container, classes.left].join(" ")}>
        {/*<div>11:23 - 26/08/21</div>*/}
        <div>
          <img src={alertSMS} alt={"sms"} />
          <GlobalHoverCard>Αποστολή SMS από τον δήμαρχο</GlobalHoverCard>
        </div>
        <div>
          <div />
        </div>
        <section>
          <h1>
            <span>Από: </span>Δήμαρχο
          </h1>
          <p style={{ marginBottom: "10px", fontWeight: "700" }}>
            10/12/21 - 13:07
          </p>

          <>
            <p style={{ fontWeight: "700", marginBottom: "20px" }}>
              <span style={{ fontStyle: "italic" }}>Απάντηση: </span>Υπέρβαση
              PM2
            </p>
            <p style={{ marginBottom: "15px" }}>Καλησπέρα,</p>
            <p style={{ marginBottom: "15px", textAlign: "justify" }}>
              Έστειλα το συνεργείο ενημέρωσε με αν σε δύο ώρες συνεχίζεται το
              πρόβλημα.
            </p>
            <p>Δήμαρχος Ζίτσας.-</p>
          </>
        </section>
      </div>

      <div className={[classes.container, classes.right].join(" ")}>
        <div>
          <img src={alertEmail} alt={"email"} />
          <GlobalHoverCard>Αποστολή Email στον δήμαρχο</GlobalHoverCard>
        </div>

        <div>
          <div />
        </div>
        <section>
          <h1>
            <span>Προς: </span>Δήμαρχο
          </h1>
          <p style={{ marginBottom: "10px", fontWeight: "700" }}>
            10/12/21 - 12:11
          </p>
          <>
            <p style={{ fontWeight: "700", marginBottom: "20px" }}>
              Υπέρβαση PM2
            </p>
            <p style={{ marginBottom: "15px" }}>Αξιότιμε κύριε Δήμαρχε,</p>
            <p style={{ marginBottom: "15px", textAlign: "justify" }}>
              Εκ μέρους του κέντρου ελέγχου θα ήθελα να σας ενημερώσω ότι η
              μεταβλητή PM2 είναι αυξημένη κατά 3 μονάδες πάνω από το όριο εδώ
              και 3 ώρες.
            </p>

            <p style={{ marginBottom: "15px", textAlign: "justify" }}>
              Ευχαριστώ θερμά για την ανταπόκρισή σας.
            </p>
            <p>Με εκτίμηση,</p>
            <p style={{ paddingLeft: "20px" }}>ο χειριστής.-</p>
          </>
        </section>
      </div>

      <div className={[classes.container, classes.right].join(" ")}>
        <div>
          <img src={alertSMS} alt={"sms"} />
          <GlobalHoverCard>Αποστολή SMS στον δήμαρχο</GlobalHoverCard>
        </div>

        <div>
          <div />
        </div>
        <section>
          <h1>
            <span>Προς: </span>Δήμαρχο
          </h1>
          <p style={{ marginBottom: "10px", fontWeight: "700" }}>
            10/12/21 - 11:55
          </p>
          <p style={{ marginBottom: "15px", textAlign: "justify" }}>
            Εκ μέρους του κέντρου ελέγχου θα ήθελα να σας ενημερώσω ότι η
            μεταβλητή PM2 είναι αυξημένη κατά 3 μονάδες πάνω από το όριο εδώ και
            3 ώρες. Με εκτήμιση, ο χειρστής.
          </p>
        </section>
      </div>

      <div className={[classes.container, classes.right].join(" ")}>
        <div>
          <img src={alertCall} alt={"call"} />
          <GlobalHoverCard>Τηλεφωνική κλήση στον δήμαρχο</GlobalHoverCard>
        </div>

        {/* white circle */}
        <div>
          <div />
        </div>

        <section>
          <h1>
            <span>Προς: </span>Δήμαρχο
          </h1>
          <p style={{ marginBottom: "10px", fontWeight: "700" }}>
            10/12/21 - 11:49
          </p>
          <p>Αναπάντητη κλήση.</p>
        </section>
      </div>
    </div>
  );
};

export default VerticalHistory;
