import React from "react";
import classes from "./GlobalHoverCard.module.css";

const GlobalHoverCard = (props) => {
  return (
    <div className={classes.global_hover_card}>
      <div>{props.children}</div>
    </div>
  );
};

export default GlobalHoverCard;
