import React from "react";
import classes from "./Email.module.css";
import Autocomplete from "@material-ui/lab/Autocomplete";
import StyledTextField from "../../UI/input/StyleTextField";
import Card from "../../UI/cards/Card";
import { useEffect, useRef, useState } from "react";

const top100Films = [
  { title: "The Shawshank Redemption", year: 1994 },
  { title: "The Godfather", year: 1972 },
  { title: "The Godfather: Part II", year: 1974 },
];

const Email = (props) => {
  const { alertDetails } = props;
  const [sendToName, setSendToName] = useState(null);
  const [CCEmail, setCCEmail] = useState([]);
  const [listEmail, setListEmail] = useState([]);
  const [listCCEmail, setListCCEmail] = useState([]);
  const [subject, setSubject] = useState("");

  const body = useRef(null);

  useEffect(() => {
    if (alertDetails) {
      setListEmail([{ title: alertDetails.mailto }]);
      setListCCEmail([{ title: alertDetails.mailcc }]);
    }
  }, [alertDetails]);

  const submit = async () => {
    const postAction = await fetch(
      `https://zitsa.smartiscity.gr/api/api.php?func=alertaction&alert_id=${alertDetails.alert_id}&action_id=3`,
      { method: "POST" }
    ).catch((error) => console.warn(error));

    if (postAction === undefined || !postAction.ok) return;

    const data = await postAction.json();
    console.log(sendToName, subject, body.current.value);
    console.log(data);
    clear();
  };

  useEffect(() => {
    if (sendToName) {
      setSubject(alertDetails.alert_name + " - " + alertDetails.alert_object);
    }
  }, [sendToName]);

  const clear = () => {
    setSendToName(null);
    setCCEmail([]);
    setSubject("");
    body.current.value = "";
  };

  //(body.current.getElementsByTagName("textarea")[0].value)
  return (
    <Card
      title={"Ηλεκτρονική Επικοινωνία"}
      id={"emailForm"}
      onCancel={clear}
      onConfirm={submit}
      historyTitle={props.historyTitle}
      historyList={
        alertDetails.history
          ? alertDetails.history.filter((item) => item.action_id === "3")
          : []
      }
    >
      <Autocomplete
        style={{
          width: "70%",
          marginTop: "40px",
        }}
        id="Accountable-emails"
        options={listEmail}
        getOptionLabel={(option) => option.title}
        onChange={(event, selectedValue) => setSendToName(selectedValue)}
        value={sendToName}
        renderInput={(listEmail) => (
          <StyledTextField
            {...listEmail}
            style={{ width: "100%", marginLeft: "0px" }}
            id="accountableEmail"
            label="Στοιχεία Υπεύθυνου"
            type="text"
            required
          />
        )}
      />

      <Autocomplete
        style={{
          width: "70%",
        }}
        multiple
        size="small"
        id="CC-Emails"
        options={listCCEmail}
        getOptionLabel={(option) => option.title}
        onChange={(event, selectedValue) => setCCEmail(selectedValue)}
        value={CCEmail}
        renderInput={(listCCEmail) => (
          <StyledTextField
            {...listCCEmail}
            style={{ width: "100%", marginLeft: "0px" }}
            id="CCEmail"
            label="Cc"
            type="text"
          />
        )}
      />

      <StyledTextField
        id="title"
        label="Θέμα"
        type="text"
        required
        style={{ width: "70%" }}
        value={subject}
        title={subject}
      />

      <StyledTextField
        multiline
        id="title"
        label="Περιγραφή ενέργειας"
        type="text"
        rows={5}
        variant="standard"
        style={{ width: "70%" }}
        inputRef={body}
        required
      />
    </Card>
  );
};

export default Email;
