import React from "react";
import classes from "./AlertsForm.module.css";
import { Fragment, useEffect, useState } from "react";
import Title from "../../../UI/box/title/Title";
import HorizontalAlertsOptions from "../../horizontal/HorizontalAlertsOptions";
import Entity from "../../../UI/panel/entity/Entity";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";

import AlertNotAnswerdCall from "./../../../../assets/images/alerts/NotAnswerdCall.png";
import AlertAnswredCall from "./../../../../assets/images/alerts/Telephone.png";
import AlertEmail from "./../../../../assets/images/alerts/Email.png";
import AlertSms from "./../../../../assets/images/alerts/Sms.png";
import AlertHoldNotification from "./../../../../assets/images/alerts/HoldNotification.png";
import Alert from "./../../../../assets/images/alerts/alert.png";

const alertList = [
  { url: "", alt: "" },
  { url: AlertNotAnswerdCall, alt: "Αναπάντητη κλήση" },
  {
    url: AlertAnswredCall,
    alt: "Πραγματοποιήθηκε κλήση",
  },
  {
    url: AlertEmail,
    alt: "Αποστολή τουλάχιστον ενός email",
  },
  {
    url: AlertSms,
    alt: "Αποστολή τουλάχιστον ενός SMS",
  },
  {
    url: AlertHoldNotification,
    alt: "Παρακαλούθηση ειδοποίησης",
  },
];

const AlertsForm = (props) => {
  const [isInfo, setIsInfo] = useState(true);
  const [orderingAlertsList, setOrderingAlertsList] = useState([]);

  const history = useHistory();
  const { search } = useLocation();
  const { pathname } = useLocation();

  useEffect(() => {
    console.log(search, pathname);
    const typeId = new URLSearchParams(search).get("typeId");
    console.log(typeId); //12345
    const alertId = new URLSearchParams(search).get("alertId");
    console.log(alertId); //12345
    if (!typeId) props.closeSidePanel();
    else if (parseInt(typeId) === props.typeId && alertId) {
      let alertIdExist = false;
      for (let i = 0; i < props.alertsList.length; i++) {
        if (props.alertsList[i].alert_id === alertId) alertIdExist = true;
      }
      if (!alertIdExist) {
        setTimeout(() => history.push(`${pathname}`), 500);
      } else props.showAnimation(parseInt(typeId + "" + alertId));
    }
  }, [search]);

  useEffect(() => {
    if (props.alertsList) {
      const normalAlerts = [];
      const actionAlerts = [];
      const holdAlerts = [];
      props.alertsList.map((item) => {
        if (item.history.length > 0) {
          let findHold = false;
          for (let i = 0; i < item.history.length; i++) {
            if (item.history[i].action_id === "5") {
              findHold = true;
              break;
            }
          }
          if (findHold) holdAlerts.push(item);
          else actionAlerts.push(item);
        } else {
          normalAlerts.push(item);
        }
      });
      console.log(normalAlerts, actionAlerts, holdAlerts);
      const tempOrderingAlertList = normalAlerts
        .concat(actionAlerts)
        .concat(holdAlerts);

      setOrderingAlertsList(tempOrderingAlertList);
      console.log(tempOrderingAlertList);
    }
  }, [props.alertsList]);

  const selectItemHandler = (clickedSensorId) => {
    const typeId = new URLSearchParams(search).get("typeId");
    const alertId = new URLSearchParams(search).get("alertId");

    console.log("TEST", alertId, clickedSensorId);
    if (
      typeId &&
      alertId &&
      parseInt(typeId) === props.typeId &&
      alertId === clickedSensorId
    ) {
      console.log("ir8a");
      props.showAnimation(parseInt(typeId + "" + clickedSensorId));
    }
  };

  const showStatsHandler = () => setIsInfo((prev) => !prev);

  return (
    <div style={{ position: "relative" }}>
      <Title
        title={props.title}
        className={[classes.bigger_title, props.className].join(" ")}
        element={
          <i
            id="draggable"
            className="im im-icon-Double-Tap"
            style={{ fontSize: "32px", minWidth: "32px", cursor: "pointer" }}
          />
        }
        id={props.typeId}
      />
      {props.onClick && (
        <div
          style={{
            position: "absolute",
            left: 20,
            top: 13,
            fontSize: 40,
            cursor: "pointer",
          }}
          onClick={() => props.onClick()}
        >
          &#8619;
        </div>
      )}

      <div className={classes.background_wrapper}>
        <HorizontalAlertsOptions changePreview={showStatsHandler} />

        <ul className={classes.list_placeholder}>
          {orderingAlertsList.map((item, index) => (
            <li
              key={index}
              className={
                new URLSearchParams(search).get("alertId") === item.alert_id
                  ? classes.active
                  : item.history.length === 0
                  ? classes.alarm_entity
                  : null
              }
              style={{ justifyContent: "space-between" }}
              onClick={() => {
                // showAnimation(parseInt(typeId + "" + 1));
                selectItemHandler(item.alert_id);
                history.push(
                  `${pathname}?typeId=${props.typeId}&alertId=${item.alert_id}`
                );
              }}
            >
              <div className={classes.lights}>
                <div
                  style={
                    item.status && item.status === "0"
                      ? { backgroundColor: "red" }
                      : { backgroundColor: "green" }
                  }
                />
              </div>

              {isInfo && (
                <Entity
                  style={{ paddingRight: 0 }}
                  className={classes.remove_entity_padding}
                  title={item.alert_name}
                  // address={"Ενημερώθηκε στις: 19/11/2021"}
                  last_alert={
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={Alert}
                        alt={"last alert"}
                        width={28}
                        style={{ marginRight: 10 }}
                      />
                      {item.date_insert}
                    </div>
                  }
                  // last_alert={`Τελευταία ενημέρωση: ${item.date_insert}`}
                  icons={[]}
                />
              )}

              {!isInfo && (
                <div style={{ padding: "0 30px" }}>
                  <strong>Εκκρεμότητες:</strong>
                  <p>Πήρα τηλέφωνο</p>
                </div>
              )}

              <div className={classes.action_icons}>
                {item.history
                  .filter((item) => item.action_id < "5")
                  .map((item, index) => (
                    <img
                      key={index}
                      src={alertList[item.action_id].url}
                      alt={alertList[item.action_id].alt}
                      title={alertList[item.action_id].alt}
                      style={{ padding: "2.5px" }}
                    />
                  ))}
              </div>

              <div>
                {item.history
                  .filter((item) => item.action_id === "5")
                  .map((item, index) => (
                    <img
                      key={index}
                      src={alertList[item.action_id].url}
                      alt={alertList[item.action_id].alt}
                      title={alertList[item.action_id].alt}
                      style={{ padding: "2.5px" }}
                    />
                  ))}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default AlertsForm;
